import React, { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    setTimeout(window.location.href = 'https://www.meetup.com/PDX-Gaymers/', 10000);
  });

  return (
    <div className="App">
      <img src="logo.jpeg" width='100' alt='Logo' />
      <div>
        <p>Site coming soon. </p>
        <p>Redirecting to our Meetup page in 10 seconds. Click <strong><a href="https://www.meetup.com/PDX-Gaymers/">here</a></strong> to immediately go to our Meetup page.</p>
      </div>
    </div>
  );
}

export default App;
